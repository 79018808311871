<template>
  <div class="warehouseType">
    <div class="pvhFactory">
        <div class="">
            <div class="layTop d-flex">
                <div class="Title" style="margin-bottom: 10px;">{{ $t('Data Import') }}</div>

            </div>
            <div style="padding-top: 10px; text-align: left; align-self: flex-end;">
                <el-row class="topFromSearch">
                    <el-col class="formDiv" :xs="24" :sm="21" :md="21" :lg="21" :xl="21" style="align-self: flex-end; ">
                        <el-input style="width:550px;" v-model="searchVal" :placeholder="$t('PLEASE ENTER THE KEYWORDS TO BE SEARCHED')"></el-input>

                        <el-button :title="$t('Search')" icon="iconfont icon-textile-sousuo" size="mini" type="primary" @click="search"> {{$t('Search')}}</el-button>
                    </el-col>
                    <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3" style="padding-top: 20px; text-align: right; align-self: flex-end;">
                        
                    </el-col>
                </el-row>
            </div>
            <div style="margin-top: 10px;">
                <avue-crud class="pvhTable"
                           v-loading="loading"
                           v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <!--<template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">

                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                    {{ $t('ADD') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="editBtn(row)">{{ $t('Edit') }}</el-button>
                        <el-button type="text" size="small" @click="delBtn(row)">{{ $t('Delete') }}</el-button>
                    </template>-->

                </avue-crud>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
    import {
        GetDataImportInfos
    } from '@/api/datalog'

export default {
  name: "dataImport",
  data() {
    return {
      loading: false,
      option: [],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],
      obj: {},
      searchVal: null,
    }
  },
  created() {
    this.list();
  },
  methods: {
        init() {
            this.formData.loadId = this.$route.query.ID;
            this.getListInfo();
        },
      search() {
          this.list();
      },
    list() {
        this.loading = true;
        GetDataImportInfos(this.$route.query.ID, this.searchVal, this.page.currentPage, this.page.pageSize).then((response) => {
        this.loading = false;
        this.tableData = response.data.dataImports;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
    },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    currentChange(val) {
      this.page.currentPage = val
      this.list()
      // this.getOperateLogList();
    },
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: false,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
                label: this.$t('PO'),
                prop: 'po',
          },
          {
              label: this.$t('VenderSectionCode'),
              prop: 'venderSectionCode',
          },
          {
              label: this.$t('Supplier Code'),
              prop: 'supplierCode',
            },
            {
                label: this.$t('SupplierName'),
                prop: 'supplierName',
            },
            {
                label: this.$t('Order Date'),
                prop: 'orderDate',
                type: "date",
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                editDisabled: true
            },
            {
                label: this.$t('ProductCode'),
                prop: 'productCode',
            },
            {
                label: this.$t('COLOR'),
                prop: 'color',
            },
            {
                label: this.$t('Number'),
                prop: 'number',
            },
            {
                label: this.$t('UNIT'),
                prop: 'unitId',
            },
            {
                label: this.$t('Country of Origin'),
                prop: 'origin',
            },
            {
                label: this.$t('FIBER TYPE'),
                prop: 'fiberType',
            },
          {
              label: this.$t('ImportResult'),
              prop: 'importResult',
          }
        ]
      }
    },
  }
}
</script>

<style scoped lang="scss">
.dataImport {
  padding: 15px;

  .pvhFactory {
    padding: 15px;
    border-radius: 8px;
    background: #FFFFFF;
  }
}

.layTop {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
</style>
